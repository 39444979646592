import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import strings from "../i18n/language";

function PricingCard({ title, price, features }) {
  const subject = `${strings.LABEL_PRICING_OBJECT_INTERESTED} ${title}`;
  const mailtoLink = `mailto:contact@fidelize.org?subject=${encodeURIComponent(
    subject
  )}`;

  const handleClick = () => {
    window.location.href = mailtoLink;
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4" component="p" color="primary">
          {price ? `${price}/mes` : "Gratis"}
        </Typography>
        {Array.isArray(features) ? (
          <List>
            {features.map((feature, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>{features}</Typography>
        )}
        <Box mt={2} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleClick}>
            {strings.LABEL_BUTTON_CONTACT}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default function Pricing() {
  const plans = [
    {
      type: "free",
      title: strings.LABEL_PRICING_BASIC_TITLE,
      features: [
        strings.LABEL_PRICING_BASIC_FEATURE_1,
        strings.LABEL_PRICING_BASIC_FEATURE_2,
        strings.LABEL_PRICING_BASIC_FEATURE_3,
        strings.LABEL_PRICING_BASIC_FEATURE_4,
        strings.LABEL_PRICING_BASIC_FEATURE_5,
      ],
    },
    {
      type: "basic",
      title: strings.LABEL_PRICING_STANDARD_TITLE,
      price: "24 990",
      features: [
        strings.LABEL_PRICING_STANDARD_FEATURE_1,
        strings.LABEL_PRICING_STANDARD_FEATURE_2,
        strings.LABEL_PRICING_STANDARD_FEATURE_3,
        strings.LABEL_PRICING_STANDARD_FEATURE_4,
        strings.LABEL_PRICING_STANDARD_FEATURE_5,
        strings.LABEL_PRICING_STANDARD_FEATURE_6,
        strings.LABEL_PRICING_STANDARD_FEATURE_7,
      ],
    },
    {
      type: "premium",
      title: strings.LABEL_PRICING_PREMIUM_TITLE,
      price: "49 990",
      features: [
        strings.LABEL_PRICING_PREMIUM_FEATURE_1,
        strings.LABEL_PRICING_PREMIUM_FEATURE_2,
        strings.LABEL_PRICING_PREMIUM_FEATURE_3,
        strings.LABEL_PRICING_PREMIUM_FEATURE_4,
        strings.LABEL_PRICING_PREMIUM_FEATURE_5,
        strings.LABEL_PRICING_PREMIUM_FEATURE_6,
        strings.LABEL_PRICING_PREMIUM_FEATURE_7,
      ],
    },
  ];

  return (
    <Container>
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        align="center"
        sx={{ marginY: 4 }}
        color="white"
      >
        {strings.LABEL_PRICING_CHOSE_PLAN}
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <PricingCard {...plan} />
          </Grid>
        ))}
      </Grid>
      <Box mt={4} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ width: "100%", maxWidth: 400 }}
          onClick={() => {
            const mailtoLink = `mailto:contact@fidelize.org?subject=${encodeURIComponent(
              strings.LABEL_PRICING_SCALED_PLAN
            )}`;
            window.location.href = mailtoLink;
          }}
        >
          {strings.LABEL_PRICING_SCALED_PLAN}
        </Button>
      </Box>
    </Container>
  );
}
